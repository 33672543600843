import GATSBY_COMPILED_MDX from "/Users/a12583/work/zensys/ca-infosys/git-saiyou/src/team/hr/index.mdx";
import React from "react";
import {graphql, Link} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Animate from "../../components/animate";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import RetatedList from "../../components/relatedList";
import * as styles from "../../styles/_pageTeamDetail.module.scss";
import {linkButton, posi, nega, iconInternal} from '../../styles/_btn.module.scss';
const TeamDetailPage = ({data, children}) => {
  return React.createElement(Layout, {
    home: false
  }, React.createElement("header", {
    className: "content-header"
  }, React.createElement(Animate, {
    className: "fadeIn",
    triggerOnce: true
  }, React.createElement("h1", {
    className: "title1",
    style: {
      transitionDelay: `1s`
    }
  }, React.createElement("div", {
    className: styles.title1
  }, React.createElement(GatsbyImage, {
    image: getImage(data.mdx.frontmatter.logo.src),
    alt: data.mdx.frontmatter.logo.alt,
    placeholder: "transparent"
  }))))), React.createElement("div", {
    className: styles.wrapper
  }, React.createElement("div", {
    className: styles.info
  }, data.mdx.frontmatter.tags ? React.createElement("p", {
    className: styles.tags
  }, data.mdx.frontmatter.tags.map((tag, index) => React.createElement("span", {
    key: index,
    className: styles.tag
  }, tag))) : ``, React.createElement("p", {
    className: styles.jobcategories
  }, data.mdx.frontmatter.jobcategories.map((jobcategory, index) => React.createElement("span", {
    key: index,
    className: styles.jobcategory
  }, jobcategory)))), React.createElement("article", {
    className: styles.article
  }, children), React.createElement("section", {
    className: styles.careers
  }, React.createElement(Animate, {
    className: "fadeIn",
    triggerOnce: true
  }, React.createElement("h2", {
    className: styles.join
  }, "Join Us"), React.createElement("p", null, "グループIT推進本部では一緒に働く仲間を募集しています。"), React.createElement("p", null, React.createElement(Link, {
    to: "/careers/",
    className: `${linkButton} ${nega}`
  }, " 採用情報", React.createElement("span", {
    className: iconInternal
  }))))), React.createElement(RetatedList, {
    order: data.mdx.frontmatter.order
  }), React.createElement("div", {
    className: styles.gotoTeam
  }, React.createElement(Animate, {
    className: "fadeIn",
    triggerOnce: true
  }, React.createElement("p", null, React.createElement(Link, {
    to: "/team/",
    className: `${linkButton} ${posi}`
  }, " すべてのチーム", React.createElement("span", {
    className: iconInternal
  })))))));
};
export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        name
        slug
        order
        heading
        logo {
          alt
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        jobcategories
        tags
      }
    }
  }
`;
export const Head = ({data}) => React.createElement(Seo, {
  title: data.mdx.frontmatter.name,
  pathname: `/team/${data.mdx.frontmatter.slug}/`
});
TeamDetailPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(TeamDetailPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
